<template>
<v-container fluid>
  <div class="d-flex align-center cyan lighten-5 pa-3 ma-n3">
    <div class="text-h6 ml-4">企业信息设置</div>
    <v-spacer></v-spacer>
  </div>
  <!-- company name -->
  <div class="pa-5">
    <div class="d-flex mt-7 align-center">
      <v-icon large color="orange">mdi-page-layout-header</v-icon>
      <div class="text-subtitle-1 ml-2">企业名称</div>
      <v-spacer></v-spacer>
    </div>
    <div>
      <div class="d-flex py-5 px-10 align-center">
        <div class="text-subtitle-2 mb-4">企业全称</div>
        <!-- company full name -->
        <div style="width:300px;" class="ml-3">
          <v-text-field v-model="newName" dense outlined></v-text-field>
        </div>
        <div v-if="newName!==oldName" class="mb-4 ml-4">
          <v-btn @click="saveCompanyFullName" color="primary" class="mx-1" small>保存</v-btn>
          <v-btn @click="newName=oldName" small class="mx-1">取消</v-btn>
        </div>
        <v-spacer></v-spacer>
      </div>
    </div>
    <div class="mt-n12">
      <div class="d-flex py-5 px-10 align-center">
        <div class="text-subtitle-2 mb-4">企业简称</div>
        <div style="width:300px;" class="ml-3">
          <!-- company short name -->
          <v-text-field v-model="newShortName" dense outlined></v-text-field>
        </div>
        <div v-if="oldShortName!==newShortName" class="mb-4 ml-4">
          <v-btn @click="saveShortName" color="primary" class="mx-1" small>保存</v-btn>
          <v-btn @click="newShortName=oldShortName" small class="mx-1">取消</v-btn>
        </div>
        <v-spacer></v-spacer>
      </div>
    </div>
  </div>
  <v-divider></v-divider>
  <!-- logo -->
  <div class="pa-5">
    <div class="d-flex align-center">
      <v-icon color="green" large>mdi-diamond</v-icon>
      <div class="text-subtitle-1 ml-3">企业<span class="text-caption">logo</span></div>
      <div class="text-caption grey--text ml-3">（企业logo将会用于所有页面页头）</div>
      <v-spacer></v-spacer>
    </div>
    <div class="d-flex">
      <div class="pa-5">
        <div>
          <img :src="logoUrl" style="width:400px;height:200px;border:1px solid grey;object-fit:contain;">
        </div>
        <input ref="fileUpload" style="display:none;" type="file" accept="image/JPG, image/png, image/jpeg, image/bmp, image/gif, image/tif" @change="fileUpload">
        <div style="width:400px;">
          <v-btn @click="openFile" dark color="blue" block>上传logo</v-btn>
        </div>
      </div>
      <div class="ma-5">
        <div class="text-subtitle-1 mb-5">预览</div>
        <div class="d-flex align-center">
          <img :src="logoUrl" style="width:150px;height:30px;border:1px solid grey;object-fit:contain;">
          <div class="text-caption grey--text ml-10">页头 150*30</div>
        </div>
      </div>
      <v-spacer></v-spacer>
    </div>
  </div>
  <v-divider></v-divider>
  <div class="pa-5">
    <div class="d-flex align-center">
      <v-icon color="yellow" large>mdi-camera-iris</v-icon>
      <div class="text-subtitle-1 ml-3">浏览器图标</div>
      <v-spacer></v-spacer>
    </div>
    <div class="pa-5">
      <div style="position:relative;">
        <img :src="tabImage">
        <img :src="iconUrl" style="width:18px;height:18px;position:absolute;left:54px;top:10px;">
      </div>
      <input ref="iconUpload" style="display:none;" type="file" accept="image/JPG, image/png, image/jpeg, image/bmp, image/gif, image/tif" @change="iconUpload">
      <div style="width:400px;">
        <v-btn @click="openIconUpload" dark color="blue" block>上传图标</v-btn>
      </div>
      <div class="text-caption mt-12">提示：图标尺寸18*18，超过将被浏览器压缩；</div>
    </div>
  </div>
</v-container>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex';
import endpoints from "@/config/endpoints";
import Images from '@/constants/image.js'
export default {
  name: 'CompanyInfoPage',
  computed: {
    ...mapGetters('department', ['departTree']),
  },
  data: () => ({
    oldName: '',
    newName: '',
    id: null,
    oldShortName: '',
    newShortName: '',
    logoUrl: '',
    iconUrl: '',
    tabImage: Images.tab,
  }),
  methods: {
    ...mapActions('department', ['getDepartments', 'updateDepartment']),
    ...mapActions('fileUpload', ['filesUpload']),
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    saveCompanyFullName() {
      this.updateDepartment({
        id: this.id,
        departmentOaName: this.newName,
      });
      this.oldName = this.newName;
    },
    saveShortName() {
      this.updateDepartment({
        id: this.id,
        departmentShortName: this.newShortName,
      });
      this.oldShortName = this.newShortName;
    },
    openFile() {
      this.$refs.fileUpload.click();
    },
    openIconUpload() {
      this.$refs.iconUpload.click();
    },
    iconUpload() {
      const file = this.$refs.iconUpload.files[0];
      let formData = new FormData();
      formData.append('files', file);
      this.filesUpload(formData).then(res => {
        this.setSettingCall({
          st_category_id: 101,
          st_category_type: 100,
          st_value: JSON.stringify(res[0])
        });
        this.iconUrl = `${endpoints.FILE_DOWNLOAD}/${res[0].file_id}`;
        window.location.reload();
      });
    },
    fileUpload() {
      const file = this.$refs.fileUpload.files[0];
      let formData = new FormData();
      formData.append('files', file);
      this.filesUpload(formData).then(res => {
        this.setSettingCall({
          st_category_id: 100,
          st_category_type: 100,
          st_value: JSON.stringify(res[0])
        });
        this.logoUrl = `${endpoints.FILE_DOWNLOAD}/${res[0].file_id}`
      });
    }
  },
  mounted() {
    this.getDepartments().then(res => {
      this.oldName = res[0].departmentOaName;
      this.newName = res[0].departmentOaName;
      this.id = res[0].id;
      this.newShortName = res[0].departmentShortName;
      this.oldShortName = res[0].departmentShortName;
    });
    this.getSettingCall({
      st_category_id: 100,
      st_category_type: 100
    }).then(res => {
      this.logoUrl = `${endpoints.FILE_DOWNLOAD}/${JSON.parse(res).file_id}`
    });
    this.getSettingCall({
      st_category_id: 101,
      st_category_type: 100
    }).then(res => {
      this.iconUrl = `${endpoints.FILE_DOWNLOAD}/${JSON.parse(res).file_id}`
    });
  }
}
</script>
